import React from "react"
import Layout from "../components/layout/Layout"

const impressum = () => {
  return (
    <Layout>
      <section className="px-4 py-24 mx-auto max-w-7xl min-h-full">
        <div className="container">
          <div className="flex flex-wrap">
            <div className="w-full px-4">
              <div className="mb-12 lg:mb-20 max-w-[620px]">
                <h3 class="mb-8 text-4xl font-bold leading-none tracking-tighter text-primary md:text-7xl lg:text-5xl">
                  Impressum
                </h3>
                <p
                  className="
              text-md            
              font-semibold
              leading-relaxed
              sm:leading-relaxed
              text-body-color
              
            "
                >
                  Angaben gemäß § 5 TMG
                </p>

                <ul
                  className="
              text-md     
              leading-relaxed
              sm:leading-relaxed
              text-body-color
              list-none
              my-4
            "
                >
                  <li>Friederike und Hubertus Bromberger GbR</li>
                  <li>Rathausstraße 9</li>
                  <li>87448 Waltenhofen</li>
                </ul>

                <ul
                  className="
              text-md           
              leading-relaxed
              sm:leading-relaxed
              text-body-color
              list-none
              my-4
            "
                >
                  <li className="font-semibold">Vertreten durch:</li>
                  <li>Friederike und Hubertus Bromberger</li>
                </ul>

                <ul
                  className="
              text-md     
              leading-relaxed
              sm:leading-relaxed
              text-body-color
              list-none
              my-4
            "
                >
                  <li className="font-semibold">Kontakt:</li>
                  <li>Telefon: 08303 - 858</li>
                  <li>E-Mail: info@zml-allgäu.de</li>
                </ul>

                <ul className="text-md leading-relaxed sm:leading-relaxed text-body-color list-none">
                  <li className="font-semibold">Aufsichtsbehörde:</li>
                  <li>Gesundheitsamt Sonthofen</li>
                </ul>

                <ul className="text-md leading-relaxed sm:leading-relaxed text-body-color list-none my-4">
                  <li className="font-semibold">Credits:</li>
                  <li>
                    <a href="https://www.freepik.com/vectors/rehab">
                      Rehab vector created by macrovector - www.freepik.com
                    </a>
                  </li>
                  <li><a href='https://www.freepik.com/vectors/physiotherapy-logo'>Physiotherapy logo vector created by freepik - www.freepik.com</a></li>
                  <li>
                    <a href="https://www.freepik.com/photos/physiotherapy">
                      Physiotherapy photo created by javi_indy - www.freepik.com
                    </a>
                  </li>
                  <li>
                    Photo by{" "}
                    <a href="https://unsplash.com/@sincerelymedia?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
                      Sincerely Media
                    </a>{" "}
                    on{" "}
                    <a href="https://unsplash.com/s/photos/physio?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText">
                      Unsplash
                    </a>
                  </li>
                  <li><a href='https://www.freepik.com/photos/forest-mountains'>Forest mountains photo created by wirestock - www.freepik.com</a></li>
                  <li><a href="http://www.onlinewebfonts.com">oNline Web Fonts</a></li>
                  
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default impressum
